import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

// Component styles
const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    flexGrow: 1,
  },
  noPadding: {
    padding: 0,
  },
});

const useStyles = makeStyles(styles);

const PortletContent = props => {
  const { className, children, noPadding, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
    },
    className,
  );

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

PortletContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default PortletContent;
