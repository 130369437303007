import Debug from 'debug';
import i18n from 'i18next';
import { errorsNames as httpErrorsNames } from '@gls_spain/frontend-api-client';
import { errorsNames } from '@gls_spain/gps-common/errors';

const debug = Debug('GPS:notifyApiClientErrors');

export default error => {
  let message;
  let variant = 'error';

  debug('%o', error);
  switch (error.name) {
    case httpErrorsNames.BadRequestError:
      message = i18n.t('errors.genericHTTPError', { error: 400 });
      break;
    case httpErrorsNames.InvalidUsernameOrPasswordError:
      message = i18n.t('errors.invalidUsernameOrPasswordError');
      break;
    case httpErrorsNames.InvalidUserInCookieOrHeaderError:
    case httpErrorsNames.InvalidCookieOrHeaderError:
    case httpErrorsNames.MissingCookieOrHeaderError:
      message = i18n.t('errors.sessionExpired');
      variant = 'default';
      break;
    case httpErrorsNames.UnauthorizedError:
      message = i18n.t('errors.genericHTTPError', { error: 401 });
      break;
    case httpErrorsNames.ForbiddenError:
      message = i18n.t('errors.genericHTTPError', { error: 403 });
      break;
    case httpErrorsNames.UnsupportedMediaTypeError:
      message = i18n.t('errors.genericHTTPError', { error: 415 });
      break;
    case httpErrorsNames.InternalServerErrorError:
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
    case httpErrorsNames.ConnectionRefusedError:
    case httpErrorsNames.ConnectionTimeoutError:
    case httpErrorsNames.ConnectionUnknownError:
      message = i18n.t('errors.connectionError');
      break;
    case errorsNames.UserAlreadyExistsError:
      // tried to create a user with the same _id or username as an existing one, should only happen for the username
      message = i18n.t('errors.userAlreadyExistsError');
      break;
    case errorsNames.NetworkAlreadyExistsError:
    case errorsNames.ShopAlreadyExistsError:
    case errorsNames.ExpeditionAlreadyExistsError:
      // tried to create a network/shop/expedition with the same _id as an existing one, should never happen because the ui does not allow it
      message = i18n.t('errors.alreadyExistsError');
      break;
    case errorsNames.UserDoesNotExistError:
    case errorsNames.NetworkDoesNotExistError:
    case errorsNames.ShopDoesNotExistError:
    case errorsNames.ExpeditionDoesNotExistError:
      // tried to update/delete a resource and it no longer exists, should never happen unless someone deleted it directly from the db
      message = i18n.t('errors.doesNotExistError');
      break;
    case errorsNames.UserCannotBeUpdatedError:
      // super admin tried to set loginEnabled=false on himself, should never happen because the ui does not allow it
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
    case errorsNames.UserCannotBeDeletedError:
    case errorsNames.NetworkCannotBeDeletedError:
    case errorsNames.ShopCannotBeDeletedError:
    case errorsNames.ExpeditionCannotBeDeletedError:
      // tried to delete a resource with deletable=false, should never happen because the ui does not allow it
      message = i18n.t('errors.cannotBeDeletedError');
      break;
    case errorsNames.WrongPasswordError:
      // user tried to update his password and the old password is wrong
      message = i18n.t('errors.wrongPasswordError');
      break;
    case errorsNames.WrongNetworkError:
      // a network admin tried to access a resource on another network, should never happen because the ui does not allow it
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
    case errorsNames.WrongShopError:
      // a shop user tries to access a resource on another shop, should never happen because the ui does not allow it
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
    case errorsNames.ShopNotEnabledError:
      // a super admin tried to create an expedition for a disabled shop, should never happen because the ui cannot create expeditions
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
    default:
      message = i18n.t('errors.genericHTTPError', { error: 500 });
      break;
  }

  return {
    message,
    options: {
      variant,
    },
  };
};
