// Palette
import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    padding: '14px 14px 14px 14px',
  },
  head: {
    fontSize: '14px',
    color: palette.text.primary,
  },
};
