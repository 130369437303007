// import palette from '../palette';

export default {
  primary: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  secondary: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
};
