export default {
  detail: 'Detalle',
  config: 'Configuración',
  holidays: 'Inactividad',
  admin: 'Administradores',
  shops: 'Tiendas',
  users: 'Usuarios',
  expeditions: 'Expediciones',
  rates: 'Tarifas',
  sales: 'Ventas',
};
