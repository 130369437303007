import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { urls } from '../config/urls';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(-254px + 100vh)',
  },
  wrapperTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  mt2: {
    marginTop: theme.spacing(3),
  },
});

const useStyles = makeStyles(styles);

// eslint-disable-next-line react/display-name
const AdapterLink = forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const Error404 = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.wrapperTitle}>
        <Typography component="h1" variant={'h1'} color="textPrimary">
          {t('views.error404.title')}
        </Typography>
        <Typography component="h6" variant={'h6'} color="textSecondary">
          {t('views.error404.subtitle')}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          className={classes.mt2}
          component={AdapterLink}
          to={urls.superAdminsList()}
        >
          {t('buttons.home')}
        </Button>
      </div>
    </div>
  );
};

export default Error404;
