import { decorate, observable, computed, action } from 'mobx';

// Todo delete network selected tab
class UI {
  constructor() {
    this.loadingComponents = new Set();
    this.isDialogOpen = false;
    this.dialogTitle = '';
    this.dialogContentText = '';
    this.dialogActions = [];
  }

  get isLoading() {
    return this.loadingComponents.size > 0;
  }

  openDialog({ title, contentText, actions }) {
    this.dialogTitle = title;
    this.dialogContentText = contentText;
    this.dialogActions = actions;
    this.isDialogOpen = true;
  }

  closeDialog() {
    this.isDialogOpen = false;
  }
}

decorate(UI, {
  loadingComponents: observable,
  isLoading: computed,
  isDialogOpen: observable,
  openDialog: action.bound,
  closeDialog: action.bound,
});

export default UI;
