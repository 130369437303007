export const COUNTRIES = {
  SPAIN: 'SPAIN',
  PORTUGAL: 'PORTUGAL',
};

function getApiBasedInDomain() {
  const { domain } = document;
  let baseApiUrl;
  if (domain.includes('gls-spain.dev')) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_PRE_PRODUCTION_SP;
  } else if (domain.includes('gls-spain.es')) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_PRODUCTION_SP;
  } else if (domain.includes('gls-portugal.dev')) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_PRE_PRODUCTION_PT;
  } else if (domain.includes('gls-portugal.pt')) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_PRODUCTION_PT;
  } else if (domain.includes('albo.dev')) {
    baseApiUrl = process.env.REACT_APP_BASE_URL_STAGING;
  } else {
    baseApiUrl = process.env.REACT_APP_BASE_URL_LOCAL;
  }
  return baseApiUrl;
}

export function getCoutryByDomain() {
  const { domain } = document;
  let country;
  if (domain.includes('gls-spain.dev') || domain.includes('gls-spain.es')) {
    country = COUNTRIES.SPAIN;
  } else if (domain.includes('gls-portugal.dev') || domain.includes('gls-portugal.pt')) {
    country = COUNTRIES.PORTUGAL;
  } else if (domain.includes('albo.dev')) {
    country = COUNTRIES.SPAIN;
  } else {
    country = COUNTRIES.PORTUGAL;
  }
  return country;
}

export default getApiBasedInDomain;
