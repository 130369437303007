import forms from './forms';
import tables from './tables';
import buttons from './buttons';
import breadcrumbs from './breadcrumbs';
import labels from './labels';
import menu from './menu';
import tabs from './tabs';

export default {
  tables: {
    ...tables,
  },
  forms: {
    ...forms,
  },
  buttons: {
    ...buttons,
  },
  breadcrumbs: {
    ...breadcrumbs,
  },
  labels: {
    ...labels,
  },
  menu: {
    ...menu,
  },
  tabs: {
    ...tabs,
  },
  notifications: {
    newVersionAvailable: 'Nueva versión disponible',
    updated: 'Actualizado correctamente',
    error: 'No puede actiañozarse',
    uploaded: 'Subido correctamene',
    deleted: 'Eliminado correctamente',
  },
  errors: {
    genericHTTPError:
      'Hay un problema ({{error}}). Vuelve a intentarlo más tarde o ponte en contacto con el servicio de soporte.',
    invalidUsernameOrPasswordError: 'Usuario o contraseña inválido',
    sessionExpired: 'La sesión ha caducado, inicie sesión de nuevo',
    connectionError: 'No se puede conectar con el servidor. Vuelve a intentarlo más tarde.',
    userAlreadyExistsError: 'El usuario ya existe',
    alreadyExistsError: 'El recurso ya existe',
    doesNotExistError: 'El recurso no existe',
    cannotBeDeletedError: 'El recurso no se puede eliminar',
    wrongPasswordError: 'Contraseña actual inválida',
    derivationNotAllowed: 'La expedicion tiene que estar recepcionada para poder cambiar el precio',
  },
  dialogs: {
    delete: {
      title: '¿Estás seguro de que quieres eliminar {{name}}?',
      contentText: 'Esta operación no se puede deshacer',
    },
    holidays: {
      dateTitle:'Fechas ya utilizadas',
      dateBody:'Las fechas seleccionadas ya están siendo utilizadas, por favor revíselo y vuelva a intentarlo.',
      dateButton:'Ok',
    },
    expeditionDetail: {
      title: 'Detalle del envío',
      destinationSection: 'Destino',
      senderSection: 'Remitente',
      senderName: 'Nombre del remitente',
      destinationName: 'Nombre del destinatario',
      contact: 'Contacto',
      department: 'Departmento',
      address: 'Dirección',
      status: 'Estado',
      packageCount: 'Número de paquetes',
      incomingDate: 'Fecha de registro',
      receivedFromDate: 'Fecha de recepción',
      givenToDate: 'Fecha de entrega',
      expirationDate: 'Fecha de caducidad',
      proveOfDelivery: 'POD',
      totalPrice: 'Precio total',
      codeExp: 'Código de expedición',
      weight: 'Peso',
      noReferencesTypeC: 'No hay referencias de tipo C',
      referenceTypeC: 'Referencia tipo C',
      barcode: 'Código de barras',
      receivedFrom: {
        agency: 'Agencia',
        code: 'Código',
        name: 'Nombre',
        nif: 'Identificación',
      },
      givenTo: {
        agency: 'Agencia',
        code: 'Código',
        name: 'Nombre',
        nif: 'Identificación',
      },
      expired: 'Caducado',
      senderTitle: 'Información del remitente',
      destinationTitle: 'Información del destinatario',
      shippingTitle: 'Información del envío',
      shippingStatus: 'Estado del envío',
      dropShipping: 'Derivado',
      received: 'Recepcionado',
      delivered: 'Entregado',
      willExpire: 'Caducará',
      itExpired: 'Caducaba',
    },
  },
  footer: {
    rights: 'GLS Spain 2019',
    caption: 'Your high class parcel service',
  },
  views: {
    error404: {
      title: '404: The page you are looking for isn’t here',
      subtitle:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
    },
  },
};
