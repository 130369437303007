import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

// Component styles
const styles = theme => {
  return {
    root: {
      borderRadius: '4px',
    },
    squared: {
      borderRadius: 0,
    },
    outlined: {
      border: `1px solid ${theme.palette.border}`,
    },
  };
};

const useStyles = makeStyles(styles);

const CustomPaper = props => {
  const { className, outlined, squared, children, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.squared]: squared,
      [classes.outlined]: outlined,
    },
    className,
  );

  return (
    <Paper {...rest} className={rootClassName}>
      {children}
    </Paper>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.number,
  outlined: PropTypes.bool,
  squared: PropTypes.bool,
};

CustomPaper.defaultProps = {
  squared: false,
  outlined: true,
  elevation: 0,
};

export default CustomPaper;
