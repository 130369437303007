export default {
  detail: 'Detalhe',
  config: 'Configuração',
  holidays: 'Inactividade',
  admin: 'Administradores',
  shops: 'Lojas',
  users: 'Utilizadores',
  expeditions: 'Expedições',
  rates: 'Tarifas',
  sales: 'Vendas',
};
