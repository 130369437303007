import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import { Label } from './index';

const styles = theme => ({
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    // marginRight: theme.spacing(1),
  },
});

const statusColors = {
  true: 'success',
  false: 'error',
};

const useStyles = makeStyles(styles);

const StatusEnabled = ({ enabled }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <div className={classes.statusWrapper}>
      <Label className={classes.status} color={statusColors[enabled]}>
        {enabled ? t('labels.enabled') : t('labels.notEnabled')}
      </Label>
    </div>
  );
};

StatusEnabled.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default StatusEnabled;
