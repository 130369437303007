import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

const Center = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.progress}>{children}</div>
    </div>
  );
};

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Center;
