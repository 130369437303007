/* eslint-disable import/prefer-default-export */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Box } from './box-open-solid.svg';
import { ReactComponent as Car } from './car-solid.svg';
import { ReactComponent as User } from './user-friends-solid.svg';
import { ReactComponent as Euro } from './euro-sign-solid.svg';
import { ReactComponent as Shop } from './store-solid.svg';
import { ReactComponent as Report } from './report.svg';
import { ReactComponent as AddressCard } from './address-card-solid.svg';
import { ReactComponent as MapSigns } from './map-signs-solid.svg';
import { ReactComponent as DriverDelivery } from './driverDelivery.svg';


export { default as CustomerDropoff } from './CustomerDropoff';
export { default as CustomerPickup } from './CustomerPickup';
export { default as Coins } from './Coins';
export { default as Height } from './Height';

export const BoxIcon = props => (
  <SvgIcon {...props}>
    <Box
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const CarIcon = props => (
  <SvgIcon {...props}>
    <Car
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const UserIcon = props => (
  <SvgIcon {...props}>
    <User
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const EuroIcon = props => (
  <SvgIcon {...props}>
    <Euro
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const ShopIcon = props => (
  <SvgIcon {...props}>
    <Shop
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const ReportIcon = props => (
  <SvgIcon {...props}>
    <Report
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const AddressCardIcon = props => (
  <SvgIcon {...props}>
    <AddressCard
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);

export const MapSignsIcon = props => (
  <SvgIcon {...props}>
    <MapSigns
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);


export const DriverDeliveryIcon = props => (
  <SvgIcon {...props}>
    <DriverDelivery
      style={{
        height: '22px',
        width: '22px',
        fontSize: '22px',
      }}
    />
  </SvgIcon>
);
