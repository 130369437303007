import React from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import Page from './Page';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  },
}));

function LoadingScreen() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={'superAdmins - GLS'}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Page>
  );
}

export default LoadingScreen;
