import React, { useState } from 'react';
import clsx from 'classnames';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';

const styles = theme => ({
  input: {
    margin: '0',
  },
  layout: {
    display: 'flex',
    // alignItems: 'center',
  },
  ml: {
    marginLeft: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

const SearchCodeExp = ({ onSearch, className }) => {
  const [inputValue, setValue] = useState('');
  const classes = useStyles();
  const [t] = useTranslation();

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleSearch = e => {
    e.preventDefault();
    onSearch(inputValue);
    setValue('');
  };

  return (
    <form onSubmit={handleSearch}>
      <div className={clsx(classes.layout, className)}>
        <TextField
          className={classes.input}
          value={inputValue}
          onChange={handleChange}
          margin="dense"
          placeholder={t('labels.searchByCodeExp')}
          variant={'outlined'}
        />
        <Button
          className={classes.ml}
          color="secondary"
          size="small"
          variant="contained"
          style={{ textTransform: 'none' }}
          onClick={handleSearch}
          type={'submit'}
        >
          {t('buttons.search')}
        </Button>
      </div>
    </form>
  );
};

SearchCodeExp.propTypes = {
  onSearch: PropTypes.func,
  className: PropTypes.string,
};

export default SearchCodeExp;
