import forms from './forms';
import tables from './tables';
import buttons from './buttons';
import breadcrumbs from './breadcrumbs';
import labels from './labels';
import menu from './menu';
import tabs from './tabs';

export default {
  tables: {
    ...tables,
  },
  forms: {
    ...forms,
  },
  buttons: {
    ...buttons,
  },
  breadcrumbs: {
    ...breadcrumbs,
  },
  labels: {
    ...labels,
  },
  menu: {
    ...menu,
  },
  tabs: {
    ...tabs,
  },
  notifications: {
    newVersionAvailable: 'New version available',
    updated: 'Updated successfully',
    error: 'Cannot update',
    uploaded: 'Uploaded successfully',
    deleted: 'Deleted successfully',
  },
  errors: {
    genericHTTPError: 'There is a problem ({{error}}). Try again later or contact support',
    invalidUsernameOrPasswordError: 'Invalid username or password',
    sessionExpired: 'The session has expired, please log in again',
    connectionError: 'Cannot connect with to server, try again later',
    userAlreadyExistsError: 'Username already exists',
    alreadyExistsError: 'Resource already exists',
    doesNotExistError: 'Resource does not exist',
    cannotBeDeletedError: 'Resource cannot be deleted',
    wrongPasswordError: 'Invalid current password',
    derivationNotAllowed: 'This expedition has to be received in order to change the price',
  },
  dialogs: {
    delete: {
      title: 'Are you sure you want to delete {{name}}?',
      contentText: 'This operation can not be undone',
    },
    holidays: {
      dateTitle:'Dates already used',
      dateBody:'The selected dates are already in use, please check and try again.',
      dateButton:'Ok',
    },
    expeditionDetail: {
      title: 'Expedition detail',
      destinationSection: 'Destination',
      senderSection: 'Sender',
      senderName: 'Sender name',
      destinationName: 'Destination name',
      contact: 'Contact',
      department: 'Department',
      address: 'Address',
      status: 'Status',
      packageCount: 'Number of packages',
      incomingDate: 'Register date',
      receivedFromDate: 'Received date',
      givenToDate: 'Given date',
      expirationDate: 'Expiration date',
      proveOfDelivery: 'POD',
      totalPrice: 'Total price',
      codeExp: 'Expedition Code',
      weight: 'Weight',
      noReferencesTypeC: 'No references type C',
      referenceTypeC: 'Reference type C',
      barcode: 'Barcode',
      receivedFrom: {
        agency: 'Agency',
        code: 'Code',
        name: 'Name',
        nif: 'Identification',
      },
      givenTo: {
        agency: 'Agency',
        code: 'Code',
        name: 'Name',
        nif: 'Identification',
      },
      expired: 'Expired',
      senderTitle: 'Sender information',
      destinationTitle: 'Recipient information',
      shippingTitle: 'Shipping Information',
      shippingStatus: 'Delivery Status',
      dropShipping: 'Derived',
      received: 'Received',
      delivered: 'Delivered',
      willExpire: 'Will expire',
      itExpired: 'Expired',
    },
  },
  footer: {
    rights: 'GLS Spain 2019',
    caption: 'Your high class parcel service',
  },
  views: {
    error404: {
      title: '404: The page you are looking for isn’t here',
      subtitle:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
    },
  },
};
