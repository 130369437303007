import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
});

const useStyles = makeStyles(styles);

const Content = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
