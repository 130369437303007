import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core';
import { StoreContext } from '../stores';

const styles = () => ({
  linearProgress: {
    height: '4px',
    position: 'fixed',
    overflow: 'hidden',
    borderRadius: '3px',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: '99999',
  },
});

const useStyles = makeStyles(styles);

const LoadingProgress = observer(props => {
  const { ui } = useContext(StoreContext);
  const classes = useStyles();
  const { transitionDelay } = props;
  return (
    <Fade
      in={ui.isLoading}
      style={{
        transitionDelay: ui.isLoading ? `${transitionDelay.toString(10)}ms` : '0ms',
      }}
      unmountOnExit
    >
      <LinearProgress className={classes.linearProgress} color="primary" />
    </Fade>
  );
});

LoadingProgress.propTypes = {
  transitionDelay: PropTypes.number,
};

LoadingProgress.defaultProps = {
  transitionDelay: 800,
};

export default LoadingProgress;
