import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    // '.capital-letter::first-letter': {
    //   textTransform: 'uppercase',
    // },
    // '.capitalize': {
    //   textTransform: 'uppercase',
    // },
  },
});

export default function GlobalCss() {
  useStyles();
  return null;
}
