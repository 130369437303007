import forms from './forms';
import tables from './tables';
import buttons from './buttons';
import breadcrumbs from './breadcrumbs';
import labels from './labels';
import menu from './menu';
import tabs from './tabs';

export default {
  tables: {
    ...tables,
  },
  forms: {
    ...forms,
  },
  buttons: {
    ...buttons,
  },
  breadcrumbs: {
    ...breadcrumbs,
  },
  labels: {
    ...labels,
  },
  menu: {
    ...menu,
  },
  tabs: {
    ...tabs,
  },
  notifications: {
    newVersionAvailable: 'Nova versão disponível',
    updated: 'Actualizado correctamente',
    error: 'Não pode actualizar',
    uploaded: 'Descarregado correctamente',
    deleted: 'Eliminado correctamente',
  },
  errors: {
    genericHTTPError: 'Há um problema ({{error}}). Volte a tentar mais tarde ou contacte o suporte.',
    invalidUsernameOrPasswordError: 'Utilizador ou palavra-passe inválidos',
    sessionExpired: 'A sessão expirou, inicie sessão novamente',
    connectionError: 'Não se pode conectar com o servidor. Volte a tentar mais tarde.',
    userAlreadyExistsError: 'O utilizador já existe',
    alreadyExistsError: 'O recurso já existe',
    doesNotExistError: 'O recurso não existe',
    cannotBeDeletedError: 'O recurso não se pode eliminar',
    wrongPasswordError: 'Palavra-passe actual inválida',
    derivationNotAllowed: 'A expedição tem que estar recepcionada para poder alterar o preço',
  },
  dialogs: {
    delete: {
      title: '¿Tem a certeza de que pretende eliminar {{name}}?',
      contentText: 'Esta operação não se pode desfazer',
    },
    holidays: {
      dateTitle:'Datas já utilizadas',
      dateBody:'As datas seleccionadas já estão em uso, por favor verifique e tente novamente.',
      dateButton:'Ok',
    },
    expeditionDetail: {
      title: 'Detalhes do envio',
      destinationSection: 'Destino',
      senderSection: 'Expedidor',
      senderName: 'Nome do expedidor',
      destinationName: 'Nome do destinatário',
      contact: 'Contacto',
      department: 'Departamento',
      address: 'Morada',
      status: 'Estado',
      packageCount: 'Número de volumes',
      incomingDate: 'Data de registo',
      receivedFromDate: 'Data de recepção',
      givenToDate: 'Data de entrega',
      expirationDate: 'Prazo para levantamento',
      proveOfDelivery: 'Comprovativo de entrega',
      totalPrice: 'Preço total',
      codeExp: 'Código de expedição',
      weight: 'Peso',
      noReferencesTypeC: 'Não existem referências de tipo C',
      referenceTypeC: 'Referência tipo C',
      barcode: 'Código de barras',
      receivedFrom: {
        agency: 'Agência',
        code: 'Código',
        name: 'Nome',
        nif: 'Identificação',
      },
      givenTo: {
        agency: 'Agência',
        code: 'Código',
        name: 'Nome',
        nif: 'Identificação',
      },
      expired: 'Expirado',
      senderTitle: 'Informção do expedidor',
      destinationTitle: 'Informação do destinatário',
      shippingTitle: 'Informacão do envio',
      shippingStatus: 'Estado do envio',
      dropShipping: 'Derivado',
      received: 'Recepcionado',
      delivered: 'Entregue',
      willExpire: 'Vai Expirar',
      itExpired: 'Expirou',
    },
  },
  footer: {
    rights: 'GLS Spain 2019',
    caption: 'O seu serviço de encomendas de qualidade',
  },
  views: {
    error404: {
      title: '404: A página que procura não foi encontrada',
      subtitle:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
    },
  },
};
