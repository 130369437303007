export default {
  listSuperAdmins: 'Super admins',
  createSuperAdmin: 'Añadir super admin',
  representatives: 'Comerciales',
  createRepresentative: 'Añadir comercial',
  listNetworks: 'Redes',
  createNetwork: 'Añadir red',
  createNetworkAdmin: 'Añadir administrador de red',
  listParcelShops: 'Tiendas',
  createShop: 'Añadir tiendas',
  createShopUsers: 'Añadir usuario de tienda',
  zones: 'Zonas',
  zonesCreate: 'Añadir zona',
  expeditions: 'Añadir expediciones',
  settings: 'Configuraciones',
  reports: 'Informes',
  reportCommercial: 'Informe comercial',
  reportShops: 'Informe General',
  reportSales: 'Informe de ventas',
  reportQuality: 'informe de calidad',
  reportOperations: 'Informe de Operaciones',
};
