import { decorate, observable, action } from 'mobx';

class NotificationsStore {
  constructor() {
    this.notifications = [];
  }

  push(snackbar) {
    this.notifications.push(snackbar);
  }

  clear() {
    this.notifications.clear();
  }
}

decorate(NotificationsStore, {
  notifications: observable,
  push: action.bound,
  clear: action.bound,
});

export default NotificationsStore;
