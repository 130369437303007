export default {
  superAdmins: 'Super admins',
  networks: 'Networks',
  parcelShops: 'Parcel shops',
  customerSupport: 'Customer support',
  salesRepresentative: 'Sales representatives',
  zones: 'Zones',
  expeditions: 'Expeditions',
  settings: 'Settings',
  reports: 'Reports',
};
