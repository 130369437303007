export default {
  headers: {
    name: 'Name',
    username: 'Username',
    email: 'Email',
    from: 'From',
    to: 'To',
    description: 'Description',
    createAt: 'Create at',
    totalDays:'Total days',
    phone: 'Phone',
    loginEnabled: 'Login',
    createdAt: 'Created',
    actions: 'Actions',
    zone: 'Zone',
    contactName: 'Contact name',
    capabilities: 'Capabilities',
    status: 'Status',
    address: 'Address',
    origin: {
      name: 'Origin name',
    },
    sender: {
      name: 'Sender name',
    },
    destination: {
      name: 'Destination name',
    },
    price: 'Price',
    size: 'Size',
    code: 'Code',
    paidToNetwork: 'Paid to the network',
    paidToShop: 'Paid to the shop',
    network: 'Network',
    commissions: {
      eachShopOpening: 'Each shop opening',
      minimumOpeningsPerMonth: 'Min. openings per month',
      eachShopManagement: 'Each shop management',
      minimumManagementMonths: 'Min. management months',
    },
    type: 'Type',
    readonly: 'Updatable',
    paymentTime: 'Payment Date',
    typeSale: 'Sale type',
    typeSaleCustomer: 'Customer',
    typeSalePS: 'ParcelShop',
    sale: 'Sale',
    viewSale: 'View sale',
  },
  footer: {
    previousPage: 'Previous page',
    nextPage: 'Next page',
    displayedRows: '{{from}}-{{to}} of {{count}}',
    rowsPerPage: 'Rows per page: ',
  },
};
