import React from 'react';

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'MuiSvgIcon-root',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <path
      d="M16.7493 18.0002H14.0905V5.9998H16.7493C17.5419 5.9998 17.9308 5.07245 17.3758 4.53672L12.9445 0.250861C12.5985 -0.0836204 12.0376 -0.0836204 11.6916 0.250861L7.26035 4.53672C6.74356 5.03548 7.02051 5.9998 7.88682 5.9998H10.5456V18.0002H7.88682C7.09418 18.0002 6.70589 18.9276 7.26035 19.4633L11.6916 23.7491C12.0376 24.0836 12.5985 24.0836 12.9445 23.7491L17.3758 19.4633C17.8926 18.9645 17.6156 18.0002 16.7493 18.0002Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
