import React from 'react';

const SVG = ({ style = {}, fill = 'currentColor', width = '100%', className = '', viewBox = '0 0 24 24' }) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`MuiSvgIcon-root ${className || ''}`}
  >
    <path
      d="M16.0587 12.1221L12.7882 12.1244L11.0865 13.8668H13.48V18.2785H11.1203L12.8001 20H20V16.2061C20 13.9395 18.2434 12.1221 16.0587 12.1221ZM7.82651 12.1452L4 16.0636L5.55375 17.6559L7.841 20H10.7019L9.02213 18.2785L7.90565 17.1342H12.2508L12.2511 15.0112H7.88886L9.00626 13.8668L10.6874 12.1452H7.82651ZM12.7842 5C11.0696 5 9.6797 6.39785 9.6797 8.12219C9.6797 9.84653 11.0696 11.2444 12.7842 11.2444C14.4988 11.2444 15.8888 9.84653 15.8888 8.12219C15.8888 6.39785 14.4988 5 12.7842 5Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
