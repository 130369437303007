export default {
  add: 'Añadir',
  update: 'Actualizar',
  search: 'Buscar',
  clear: 'Limpiar',
  install: 'Instalar',
  signin: 'Iniciar sesión',
  yes: 'Si',
  no: 'No',
  home: 'Inicio',
  download: 'Descargar',
  expire: 'Caducar',
  expiring: 'Caducando...',
  unexpiring: 'Descaducando...',
  unexpire: 'Descaducar',
};
