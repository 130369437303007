import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

import Status from './Status';

const styles = theme => ({
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
});

const statusColors = {
  true: 'success',
  false: 'danger',
};

const useStyles = makeStyles(styles);

const StatusDeletable = ({ deletable, size }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <div className={classes.statusWrapper}>
      <Status className={classes.status} color={statusColors[deletable]} size={size} />
      {deletable ? t('labels.deletable') : t('labels.notDeletable')}
    </div>
  );
};

StatusDeletable.propTypes = {
  deletable: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default StatusDeletable;
