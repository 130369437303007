import React, { useContext } from 'react';
import { Session } from '@gls_spain/frontend-session';
import { RouterStore } from 'mobx-react-router';
import GPSApiClient from '../services/gps-api-client';

import UI from './ui';
import NotificationsStore from './notificationsStore';
import notifyApiClientErrors from '../helpers/notifyApiClientErrors';

import getApiBasedInDomain from '../helpers/detectionDomain';

const baseURL = getApiBasedInDomain();

const errorsInterceptors = notificationsStore => error => {
  notificationsStore.push(notifyApiClientErrors(error));
  return Promise.reject(error);
};

export const routingStore = new RouterStore();

class Store {
  constructor() {
    this.apiClient = new GPSApiClient({ baseURL, csrfTokenName: 'x-admin-csrf-token' });

    this.notificationsStore = new NotificationsStore();

    this.routingStore = routingStore;

    this.session = new Session({
      apiClient: this.apiClient,
    });

    this.apiClient.axiosInstance.interceptors.response.use(response => {
      return response;
    }, errorsInterceptors(this.notificationsStore));

    this.ui = new UI();
  }
}

const defaultStore = new Store();

export const StoreContext = React.createContext(defaultStore);

export const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store || defaultStore}>{children}</StoreContext.Provider>;
};

export const useApiClient = () => {
  const { apiClient } = useContext(StoreContext);
  return apiClient;
};

export const useUI = () => {
  const { ui } = useContext(StoreContext);
  return ui;
};

export const useNotifications = () => {
  const { notificationsStore } = useContext(StoreContext);
  return notificationsStore;
};

export default defaultStore;
