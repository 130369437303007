export default {
  detail: 'Detail',
  config: 'Configuration',
  holidays: 'Inactivity',
  admin: 'Administrators',
  shops: 'Shops',
  users: 'Users',
  expeditions: 'Expeditions',
  rates: 'Rates',
  sales: 'Sales',
};
