export default {
  add: 'Add',
  update: 'Update',
  search: 'Search',
  clear: 'Clear',
  install: 'Install',
  signin: 'Sign in',
  yes: 'Yes',
  no: 'No',
  home: 'Home',
  download: 'Download',
  expire: 'Expire',
  expiring: 'Expiring...',
  unexpiring: 'Unexpiring...',
  unexpire: 'Unexpire',
};
