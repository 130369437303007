import React, { useContext } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from './theme';
import { LoadingProgress, Notifier } from './components';

import TextDialog from './components/TextDialog';
import Center from './components/Center';
import { StoreContext } from './stores';
import GlobalCss from './components/GlobalCss';
import routes, { renderRoutes } from './routes';

const browserHistory = createBrowserHistory();

const App = observer(() => {
  const { session } = useContext(StoreContext);
  const isLoading = session.isLoggingIn;
  return (
    <ThemeProvider theme={theme}>
      {!isLoading ? (
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <GlobalCss />
          <Notifier />
          <LoadingProgress />
          <TextDialog />
          <Router history={browserHistory}>{renderRoutes(routes)}</Router>
        </SnackbarProvider>
      ) : (
        <Center>
          <CircularProgress />
        </Center>
      )}
    </ThemeProvider>
  );
});

export default App;
