export default {
  headers: {
    name: 'Nombre',
    from: 'Desde',
    to: 'Hasta',
    description: 'Descripción',
    createAt: 'Creado el día',
    totalDays: 'Nº de días',
    username: 'Usuario',
    email: 'Email',
    phone: 'Teléfono',
    loginEnabled: 'Acceso',
    createdAt: 'Alta',
    actions: 'Acciones',
    zone: 'Zona',
    contactName: 'Contacto',
    capabilities: 'Capacidades',
    status: 'Estado',
    address: 'Dirección',
    origin: {
      name: 'Nombre de origen',
    },
    sender: {
      name: 'Remitente',
    },
    destination: {
      name: 'Nombre de destino',
    },
    price: 'Precio',
    size: 'Talla',
    code: 'Código',
    codebarPS: 'Barra de código',
    paidToNetwork: 'Pagado red',
    paidToShop: 'Pagado tienda',
    network: 'Red',
    commissions: {
      eachShopOpening: 'Por tienda abierta',
      minimumOpeningsPerMonth: 'Min. aperturas por mes',
      eachShopManagement: 'Por tienda gestionada',
      minimumManagementMonths: 'Min. de meses de gestión',
    },
    type: 'Tipo',
    readonly: 'Actualizable',
    paymentTime: 'Fecha de pago',
    typeSale: 'Tipo de venta',
    typeSaleCustomer: 'Cliente',
    typeSalePS: 'ParcelShop',
    sale: 'Venta',
    barcodes: 'Código PS',
    viewSale: 'Ver venta',
  },
  footer: {
    previousPage: 'Página previa',
    nextPage: 'Página siguiente',
    displayedRows: '{{from}}-{{to}} de {{count}}',
    rowsPerPage: 'Filas por página: ',
  },
};
