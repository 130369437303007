export default {
  searchByCodeExp: 'Código de expedición',
  enabled: 'Habilitado',
  notEnabled: 'No habilitado',
  transfer: 'Transferencia',
  zeroPercent: '0%',
  onePercent: '1%',
  sevenPercent: '7%',
  fifteenPercent: '15%',
  confirming: 'Confirming',
  incoming: 'Por llegar',
  inShop: 'En tienda',
  inShopExpired: 'Caducado',
  delivered: 'Entregado',
  returned: 'Devuelto',
  disabled: 'Deshabilitado',
  manuallyDisabled: 'Cerrado temporalmente',
  pending: 'Pendiente',
  cancelled: 'Cancelada',
  rejected: 'Rechazada',
  paid: 'Pagada',
  refunded: 'Devuelta',
  holidayType: {
    holidays: "PERIODO VACACIONAL",
    covid19: "COVID-19 / BAJA ENFERMEDAD",
    other: "OTROS MOTIVOS"
  },
  closeType: {
    embargo: "EMBARGO",
    operationalPS: "OPERATIVA PS",
    cessationActivity: "CESE DE ACTIVIDAD",
    competence: "COMPETENCIA",
    dissatisfactionGLS: "DESCONTENTO CON GLS",
    manyFewPackages: "MUCHOS/POCOS PAQUETES",
    other: "OTROS",
  },
  activity: {
    bookshop: 'Papelería-Librería-Fotocopias-Impresión',
    press: 'Kiosco prensa',
    bazaar: 'Bazar',
    telecom: 'Locutorio/Telefonía',
    mobileAccessories: 'Accesorios de telefonía',
    inkSupplies: 'Cartuchos de tinta y toner',
    computer: 'Tienda de informática',
    optics: 'Óptica',
    pharmacy: 'Farmacia/Parafarmacia',
    herbalist: 'Farmacia/Parafarmacia/Herbolario',
    food: 'Gourmet/Alimentación',
    hardware: 'Ferretería/Menaje del hogar',
    tobacco: 'Estanco',
    vaping: 'Estanco/Tiendas de artículos de fumador/vapeo',
    lottery: 'Agencia de lotería',
    clothing: 'Tienda de ropa',
    barberShop: 'Peluquería/Belleza/Estética',
    bar: 'Bar/Cafetería/Casa del pueblo',
    appliances: 'Electrodomésticos',
    repairShop: 'Recambios/Reparaciones electrodomésticos ',
    spareParts: 'Electrodomésticos/Reparación de electrodomésticos/Repuestos electrónica',
    gasStation: 'Estaciones de servicio',
    realState: 'Inmobiliaria',
    travelAgency: 'Agencia viajes',
    legal: 'Asesoría Legal/Fiscal',
    multipleServices: 'Asesoría legal/Fiscal/Servicios varios',
    academy: 'Academia',
    training: 'Academia/Escuela de música/AutoEscuela/Formación',
    sportsShop: 'Tienda de deportes/Ropa y accesorios',
    gardenAndPets: 'Jardinería/Animales y mascotas',
    photos: 'Fotografía y accesorios ',
    jewelryStore: 'Joyería/Bisutería/Accesorios moda',
    shoeShop: 'Zapaterías',
    nutritionStores: 'Gimnasio/Tiendas nutrición',
    moving: 'Mensajería/Transporte/Almacenaje/Logística/Mudanzas',
    dryCleaner: 'Lavanderías/Arreglos y costura/Tintorería',
    toyStores: 'Automoción/Hotel - hospedaje/Perfumería/Tiendas de regalos/Jugueterias',
    other: 'Otros …',
  },
  invalidOrIncomplete: 'Inválido o incompleto',
  cancelledByCustomer: 'Cancelado por el cliente',
  authorizationRefused: 'Autorización rechazada',
  paymentRequested: 'Pago solicitado',
  paymentDeleted: 'Pago eliminado',
  status: 'Estado',
  price: 'Precio',
  size: 'Talla',
  sales: {
    info: 'Información de la venta',
    fiscal: 'Datos fiscales',
    codeExp: 'Códigos de expedición',
  },
  fiscal: {
    name: 'Nombre',
    address: 'Dirección',
    email: 'Correo electrónico',
    phone: 'Teléfono',
  },
  sender: {
    info: 'Informacion del remitente',
    name: 'Nombre',
    address: 'Dirección',
    email: 'Correo electrónico',
    phone: 'Teléfono',
  },
  destination: {
    info: 'Información del destinatario',
    name: 'Nombre',
    address: 'Dirección',
    email: 'Correo electrónico',
    phone: 'Teléfono',
  },
  country: {
    PT: 'Portugal',
    ES: 'España',
  },
};
