export const urls = {
  home: () => '/',
  signIn: () => '/login',
  superAdminsList: () => '/superadmins',
  superAdminsCreate: () => '/superadmins/add',
  superAdminsDetail: (id = ':id') => `/superadmins/${id}`,
  networksList: () => '/networks',
  networksCreate: () => '/networks/add',
  networksDetail: (id = ':id') => `/networks/${id}`,
  networksDetailSection: (id = ':id', section = ':section') => `/networks/${id}/${section}`,
  networksDetailAdminsTab: (id = ':id') => `/networks/${id}/admins`,
  networksDetailShopsTab: (id = ':id') => `/networks/${id}/shops`,
  networksDetailConfigTab: (id = ':id') => `/networks/${id}/settings`,
  networkAdminsDetail: (id = ':id') => `/networksAdmins/${id}`,
  networkAdminsCreate: (id = ':id') => `/networks/${id}/admin/add`,
  shopsList: () => '/shops',
  shopsCreate: networkId => {
    if (networkId) return `/shops/add?network=${networkId}`;
    return `/shops/add`;
  },
  shopHolidayCreate: (id = ':id') => `/shops/${id}/addHolidays`,
  shopsDetail: (id = ':id') => `/shops/${id}`,
  shopsDetailSection: (id = ':id', tab = ':section') => `/shops/${id}/${tab}`,
  shopsDetailConfigTab: (id = ':id') => `/shops/${id}/settings`,
  shopUsersList: (id = ':id') => `/shops/${id}/users`,
  contactFormList: (id = ':id') => `/contactForm/find/${id}`,
  shopExpeditionsList: (id = ':id') => `/shops/${id}/expeditions`,
  shopHolidaysList: (id = ':id') => `/shops/${id}/holidays`,
  shopSalesList: (id = ':id') => `/shops/${id}/sales`,
  shopUsersCreate: (id = ':id') => `/shops/${id}/users/add`,
  shopUsersDetail: (id = ':id') => `/shopsUsers/${id}`,
  representativesList: () => '/salesRepresentative',
  representativesDetail: (id = ':id') => `/salesRepresentative/${id}`,
  representativesCreate: () => '/salesRepresentative/add',
  zonesList: () => '/zones',
  reports: () => '/reports',
  zonesCreate: () => '/zones/create',
  zonesDetail: (id = ':id') => `/zones/${id}`,
  expeditionsDetail: (id = ':id') => `/expeditions/${id}`,
  expeditionsDetailTab: (id = ':id', tab = ':tab') => `/expeditions/${id}/${tab}`,
  expeditionsConfigTab: (id = ':id') => `/expeditions/${id}/config`,
  salesDetail: (id = ':id') => `/sales/${id}`,
  settings: () => '/settings',
};
