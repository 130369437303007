import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

// Shared components
import Paper from './Paper';

// Component styles
const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const useStyles = makeStyles(styles);

const Portlet = props => {
  const { className, children, ...rest } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  return (
    <Paper {...rest} className={rootClassName} outlined squared={false}>
      {children}
    </Paper>
  );
};

Portlet.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Portlet;
