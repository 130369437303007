export default {
  headers: {
    name: 'Nome',
    from: 'A partir de',
    to: 'Para',
    description: 'descrição',
    createAt: 'Criado em',
    totalDays:'total de dias',
    username: 'Utilizador',
    email: 'Email',
    phone: 'Telefone',
    loginEnabled: 'Acesso',
    createdAt: 'Abertura',
    actions: 'Acções',
    zone: 'Zona',
    contactName: 'Pessoa de Contacto',
    capabilities: 'Habilitações',
    status: 'Estado',
    address: 'Morada',
    origin: {
      name: 'Nome do expedidor',
    },
    sender: {
      name: 'Remetente',
    },
    destination: {
      name: 'Nome do destinatário',
    },
    price: 'Valor',
    size: 'Tamanho',
    code: 'Código',
    paidToNetwork: 'Pago à rede',
    paidToShop: 'Pago à loja',
    network: 'Rede',
    commissions: {
      eachShopOpening: 'Por loja aberta',
      minimumOpeningsPerMonth: 'Min. aberturas por mês',
      eachShopManagement: 'Por loja gerida',
      minimumManagementMonths: 'Min. de meses de gestão',
    },
    type: 'Tipo',
    readonly: 'Actualizável',
    paymentTime: 'Data de pagamento',
    typeSale: 'Tipo de venda',
    typeSaleCustomer: 'Cliente',
    typeSalePS: 'ParcelShop',
    sale: 'Venda',
    viewSale: 'Ver venda',
  },
  footer: {
    previousPage: 'Página anterior',
    nextPage: 'Página seguinte',
    displayedRows: '{{from}}-{{to}} de {{count}}',
    rowsPerPage: 'Filas por página: ',
  },
};
