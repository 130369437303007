import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  noDivider: {
    borderTop: 'none',
  },
});

const useStyles = makeStyles(styles);

const PortletFooter = props => {
  const { className, noDivider, children, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
    },
    className,
  );

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

PortletFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noDivider: PropTypes.bool,
};

export default PortletFooter;
