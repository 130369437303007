export default {
  searchByCodeExp: 'Expedition Code',
  enabled: 'Enabled',
  notEnabled: 'Not enabled',
  transfer: 'Transfer',
  zeroPercent: '0%',
  onePercent: '1%',
  sevenPercent: '7%',
  fifteenPercent: '15%',
  confirming: 'Confirming',
  incoming: 'Incoming',
  inShop: 'In shop',
  inShopExpired: 'Expired',
  delivered: 'Delivered',
  returned: 'Returned',
  disabled: 'Disabled',
  manuallyDisabled: 'Temporary closed',
  pending: 'Pending',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  paid: 'Paid',
  refunded: 'Refunded',
  holidayType: {
    holidays: "PERIODO VACACIONAL",
    covid19: "COVID-19 / BAJA ENFERMEDAD",
    other: "OTROS MOTIVOS"
  },
  closeType: {
    embargo: "EMBARGO",
    operationalPS: "OPERATIVA PS",
    cessationActivity: "CESE DE ACTIVIDAD",
    competence: "COMPETENCIA",
    dissatisfactionGLS: "DESCONTENTO CON GLS",
    manyFewPackages: "MUCHOS/POCOS PAQUETES",
    other: "OTROS",
  },
  activity: {
    bookshop: 'Bookshops - stationery and supplies - digital print shops',
    press: 'Press & magazines Shops',
    bazaar: 'Bazaar',
    telecom: 'Telecommunications Stores',
    mobileAccessories: 'Mobile Accesories',
    inkSupplies: 'Ink & Tonner supplies',
    computer: 'Computer Shops & accesories',
    optics: 'Optics & glasses ',
    pharmacy: 'Pharmacy',
    herbalist: 'Pharmacy/Herbalist',
    food: 'Gourmet & Food Stores',
    hardware: 'Hardware and household items',
    tobacco: 'Sigars and Tobacco Stores',
    vaping: 'Sigars and Tobacco Stores/Vaping',
    lottery: 'Lottery',
    clothing: 'Cloth/Textile',
    barberShop: 'Beauty/Barber Shops - Aestethic centers',
    bar: 'Cafeteria/Bar',
    appliances: 'Home Appliances',
    repairShop: 'Repair centers / Small appliances / Spare parts',
    spareParts: 'Repair centers / Small appliances / Spare parts / Electronic parts',
    gasStation: 'Gas Stations',
    realState: 'Real Estate',
    travelAgency: 'Travel Agencies',
    legal: 'Tax consulting / Legal',
    multipleServices: 'Tax consulting / Legal / Multiple services',
    academy: 'Academy',
    training: 'Academy/Music School / Driving School / Training',
    sportsShop: 'Sports Shops',
    gardenAndPets: 'Pet & Garden ',
    photos: 'Photo & accesories',
    jewelryStore: 'Jewelry & Accesories/ Fancy Jewelry',
    shoeShop: 'Shoes Stores',
    nutritionStores: 'Gym / Nutrition Stores',
    moving: 'Courier / Transport / Storage / Logistics / Removals',
    dryCleaner: 'Laundries / Arrangements and sewing / Dry cleaning',
    toyStores: 'Automotive / Hotel - lodging / Perfumery / Gift shops / Toy stores',
    other: 'Others…',
  },
  invalidOrIncomplete: 'Invalid Or Incomplete',
  cancelledByCustomer: 'Cancelled By Customer',
  authorizationRefused: 'Authorization Refused',
  paymentRequested: 'Payment Requested',
  paymentDeleted: 'Payment Deleted',
  status: 'Status',
  price: 'Price',
  size: 'Size',
  sales: {
    info: 'Sale information',
    fiscal: 'Fiscal data',
    codeExp: 'Expeditions codes',
  },
  fiscal: {
    name: 'Name',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
  },
  sender: {
    info: 'Informacion del remitente',
    name: 'Name',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
  },
  destination: {
    info: 'Información del destinatario',
    name: 'Name',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
  },
  country: {
    PT:"Portugal",
    ES:"Spain"
  },
};
