import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../stores';
import { urls } from '../config/urls';

const GuestGuard = observer(({ children }) => {
  const { session } = useContext(StoreContext);
  const { isLoggedIn } = session;

  if (isLoggedIn) {
    return <Redirect to={urls.shopsList()} />;
  }

  return <>{children}</>;
});

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
