/* eslint-disable no-use-before-define */
import React, { useContext, useEffect } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles } from '@material-ui/core';
import { SettingsOutlined, DomainOutlined } from '@material-ui/icons';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { Observer } from 'mobx-react-lite';
import NavItem from './NavItem';
import { AddressCardIcon, MapSignsIcon, ShopIcon, ReportIcon, UserIcon } from '../../../icons';
import { urls } from '../../../config/urls';
import { StoreContext } from '../../../stores';

const sections = [
  {
    // subheader: 'Reports',
    items: [
      {
        title: i18n.t('menu.superAdmins'),
        icon: UserIcon,
        href: urls.superAdminsList(),
      },
      {
        title: i18n.t('menu.salesRepresentative'),
        icon: AddressCardIcon,
        href: urls.representativesList(),
      },
      {
        title: i18n.t('menu.networks'),
        icon: DomainOutlined,
        href: urls.networksList(),
      },
      {
        title: i18n.t('menu.parcelShops'),
        icon: ShopIcon,
        href: urls.shopsList(),
      },
      {
        title: i18n.t('menu.zones'),
        icon: MapSignsIcon,
        href: urls.zonesList(),
      },
      {
        title: i18n.t('menu.reports'),
        icon: ReportIcon,
        href: urls.reports(),
      },
      {
        title: i18n.t('menu.settings'),
        icon: SettingsOutlined,
        href: urls.settings(),
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoImage: {
    width: '50%',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const { session } = useContext(StoreContext);
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2}>
            <RouterLink to="/">
              <img alt="GLS logo" className={classes.logoImage} src="/images/logo.svg" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt={session.user.username} className={classes.avatar} src="/images/default-profile.jpg" />
          </Box>
          <Box mt={2} textAlign="center">
            <Observer>
              {() => (
                <>
                  <Typography className={classes.nameText} variant="h5" color="textSecondary">
                    {session.user.username}
                  </Typography>
                </>
              )}
            </Observer>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, i) => (
            <List
              key={i}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                {`v. ${process.env.REACT_APP_VERSION}`}
              </ListSubheader>
            }
          />
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
