import React from 'react';

const SVG = ({ style = {}, fill = 'currentColor', width = '100%', className = '', viewBox = '0 0 24 24' }) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`MuiSvgIcon-root ${className || ''}`}
  >
    <path
      d="M7.94129 12.1221L11.2118 12.1243L12.9135 13.8668H10.52V18.2785H12.8797L11.1999 20H4V16.2061C4 13.9395 5.75661 12.1221 7.94129 12.1221ZM16.1735 12.1452L20 16.0636L18.4463 17.6559L16.159 20H13.2981L14.9779 18.2785L16.0943 17.1342H11.7492L11.7489 15.0112H16.1111L14.9937 13.8668L13.3126 12.1452H16.1735ZM11.2158 5C12.9304 5 14.3203 6.39785 14.3203 8.12219C14.3203 9.84653 12.9304 11.2444 11.2158 11.2444C9.50116 11.2444 8.1112 9.84653 8.1112 8.12219C8.1112 6.39785 9.50116 5 11.2158 5Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
