import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  // portlet: {
  //   padding: theme.spacing(0, 4),
  // },
  tabs: {
    paddingBottom: theme.spacing(0),
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const useStyles = makeStyles(styles);

const TabsRouter = props => {
  const location = useLocation();
  const { tabs, children } = props;

  const classes = useStyles();

  const currentTab = tabs ? tabs.findIndex(tab => tab.url === location.pathname) : undefined;

  return (
    <>
      <div className={classnames(classes.portlet, tabs && classes.tabs)}>
        {tabs && (
          <Tabs value={currentTab} indicatorColor="primary" textColor="primary">
            {tabs.map((tab, index) => (
              <Tab className="capitalize" key={`${tab.name}-${index}`} label={tab.name} component={Link} to={tab.url} />
            ))}
          </Tabs>
        )}
        <Divider />
      </div>
      {children}
    </>
  );
};

TabsRouter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tabs: PropTypes.array.isRequired,
};

export default TabsRouter;
