import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon, TextField, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { observer } from 'mobx-react-lite';
import { Input as InputIcon } from '@material-ui/icons';
import SearchCodeExp from '../../../formComponents/SearchCodeExp';
import i18n from '../../../i18n';
import { StoreContext } from '../../../stores';
import { urls } from '../../../config/urls';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const lang = [
  { value: 'es', label: 'ES' },
  { value: 'en', label: 'GB' },
  { value: 'pt', label: 'PT' },
];

const LanguageSelect = ({ initialValue, onSelect }) => {
  if (initialValue === 'en-En' || initialValue === 'en') {
    initialValue = 'en';
  }
  if (initialValue === 'pt-PT' || initialValue === 'pt') {
    initialValue = 'pt';
  } else {
    initialValue = 'es';
  }
  const [selectedLang, setLang] = useState(() => initialValue);

  const handleChange = e => {
    setLang(e.target.value);
    onSelect(e.target.value);
  };

  return (
    <TextField
      id="language"
      select
      value={selectedLang}
      onChange={handleChange}
      variant="outlined"
      margin={'dense'}
      style={{ margin: 0, marginLeft: '8px' }}
    >
      {lang.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const TopBar = observer(({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { session } = useContext(StoreContext);
  const history = useHistory();

  const handleSignOut = () => {
    session.logout();
    window.sessionStorage.clear();
  };

  const handleSearch = data => {
    history.push(urls.expeditionsDetail(data));
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            {
              <img
                alt="GLS logo"
                className={classes.logoImage}
                src="/images/logo.svg"
                style={{ paddingLeft: '50px', width: '55%' }}
              />
            }
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1}></Box>
        <SearchCodeExp onSearch={handleSearch} />
        <LanguageSelect initialValue={i18n.language} onSelect={value => i18n.changeLanguage(value)} />
        <IconButton className={classes.signOutButton} onClick={handleSignOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
});

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
