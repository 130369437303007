export default {
  add: 'Adicionar',
  update: 'Actualizar',
  search: 'Procurar',
  clear: 'Limpar',
  install: 'Instalar',
  signin: 'Iniciar sessão',
  yes: 'Sim',
  no: 'Não',
  home: 'Início',
  download: 'Descarregar',
  expire: 'Expirar',
  expiring: 'Expirando...',
  unexpiring: 'Retirando expirado...',
  unexpire: 'Retirar expirado',
};
