// Palette
import palette from '../palette';

export default {
  root: {
    color: palette.text.secondary,
  },
  colorPrimary: {
    color: palette.text.secondary,
  },
};
