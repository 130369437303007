export default {
  superAdmins: 'Super admins',
  networks: 'Redes',
  parcelShops: 'ParcelShops',
  customerSupport: 'Apoio a Cliente',
  salesRepresentative: 'Comerciais',
  zones: 'Zonas',
  expeditions: 'Expedições',
  settings: 'Configurações',
  reports: 'Relatórios',
};
