import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { StoreContext } from '../stores';

const TextDialog = observer(() => {
  const { ui: uiStore } = useContext(StoreContext);
  return (
    <Dialog
      open={uiStore.isDialogOpen}
      onClose={uiStore.closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{uiStore.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{uiStore.dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {uiStore.dialogActions.map((action, index) => {
          return (
            <Button
              key={`${action.text}-${index}`}
              onClick={() => {
                if (action.onClick) {
                  action.onClick();
                }
                uiStore.closeDialog();
              }}
              color={action.color}
            >
              {action.text}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
});

export default TextDialog;
