import React, { useContext, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';

import { StoreContext } from '../stores';

const styles = () => ({
  icon: {
    color: 'white',
  },
});

const useStyles = makeStyles(styles);

const Notifier = observer(() => {
  const { notificationsStore } = useContext(StoreContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const { notifications, clear } = notificationsStore;

  const buildNotistackAction = materialUIActions => key => {
    const actionsArray = Array.isArray(materialUIActions) ? materialUIActions.slice() : [materialUIActions];
    actionsArray.push(
      <IconButton
        key="close"
        onClick={() => {
          closeSnackbar(key);
        }}
        className={classes.icon}
      >
        <Close />
      </IconButton>,
    );
    return actionsArray;
  };

  notifications.slice(); // forces mobx observation in render()
  useEffect(() => {
    notifications.forEach(notification => {
      const restOptions = omit(notification.options, 'action');
      const options = {
        action: buildNotistackAction(notification.options.action),
        ...restOptions,
      };
      enqueueSnackbar(notification.message, options);
    });
    clear();
  });
});

export default Notifier;
