export default {
  searchByCodeExp: 'Código de expedição',
  enabled: 'Habilitado',
  notEnabled: 'Não habilitado',
  transfer: 'Transferência',
  zeroPercent: '0%',
  onePercent: '1%',
  sevenPercent: '7%',
  fifteenPercent: '15%',
  confirming: 'Confirmando',
  incoming: 'A chegar',
  inShop: 'Na Loja',
  inShopExpired: 'Expirado',
  delivered: 'Entregue',
  returned: 'Devolvido',
  disabled: 'Desabilitado',
  manuallyDisabled: 'Fechado temporariamente',
  pending: 'Pendente',
  cancelled: 'Cancelada',
  rejected: 'Recusada',
  paid: 'Paga',
  refunded: 'Devolvida',
  holidayType: {
    holidays: "PERIODO VACACIONAL",
    covid19: "COVID-19 / BAJA ENFERMEDAD",
    other: "OTROS MOTIVOS"
  },
  closeType: {
    embargo: "EMBARGO",
    operationalPS: "OPERATIVA PS",
    cessationActivity: "CESE DE ACTIVIDAD",
    competence: "COMPETENCIA",
    dissatisfactionGLS: "DESCONTENTO CON GLS",
    manyFewPackages: "MUCHOS/POCOS PAQUETES",
    other: "OTROS",
  },
  activity: {
    bookshop: 'Papelaria/Livraria/Centro de Cópias-Impressão',
    press: 'Quiosque/Venda Jornais & Revistas',
    bazaar: 'Bazar',
    telecom: 'Lojas de Telecomunicações',
    mobileAccessories: 'Acessórios para Telemóveis',
    inkSupplies: 'Consumíveis Informática',
    computer: 'Lojas de Informática',
    optics: 'Óptica',
    pharmacy: 'Farmácia/Parafarmácia',
    herbalist: 'Farmácia/Parafarmácia/Ervanária',
    food: 'Gourmet/Alimentação',
    hardware: 'Ferragens/Artigos para a Casa',
    tobacco: 'Tabacaria',
    vaping: 'Tabaco / Lojas de produtos para fumadores / Vaping',
    lottery: 'Agência de Lotaria/Apostas',
    clothing: 'Loja de Vestuário',
    barberShop: 'Cabeleireiro/Centro de Estética',
    bar: 'Bar/Café/Associação recreativa',
    appliances: 'Electrodomésticos',
    repairShop: 'Peças/Reparações electrodomésticos ',
    spareParts: 'Electrodomésticos/Reparação de Electrodomésticos/Peças electrónica',
    gasStation: 'Estações de Serviço',
    realState: 'Imobiliária',
    travelAgency: 'Agência de Viagens',
    legal: 'Consultoria Jurídica/Fiscal',
    multipleServices: 'Consultoria Jurídica/Fiscal/Serviços Vários',
    academy: 'Centro de Ensino',
    training: 'Centro de Estudo/Escola de Música/Escola de Línguas/Formação',
    sportsShop: 'Loja de Desporto/Roupa e acessórios',
    gardenAndPets: 'Jardinagem/PetShop',
    photos: 'Fotografia e Acessórios ',
    jewelryStore: 'Joalharia/Bijutaria/Acessórios Moda',
    shoeShop: 'Sapataria',
    nutritionStores: 'Ginásio/Loja Nutrição',
    moving: 'Transportadora/Logística',
    dryCleaner: 'Lavandaria/Arranjos e Costura/Tinturaria',
    toyStores: 'Stand Automóveis/Hotel/Perfumaria/Loja Prendas/Loja de Brinquedos',
    other: 'Outros …',
  },
  invalidOrIncomplete: 'Inválido ou incompleto',
  cancelledByCustomer: 'Cancelado pelo cliente',
  authorizationRefused: 'Autorização recusada',
  paymentRequested: 'Pagamento solicitado',
  paymentDeleted: 'Pagamento eliminado',
  status: 'Estado',
  price: 'Valor',
  size: 'Tamanho',
  sales: {
    info: 'Informação da venda',
    fiscal: 'Dados de faturação',
    codeExp: 'Códigos de expedição',
  },
  fiscal: {
    name: 'Nome',
    address: 'Morada',
    email: 'Email',
    phone: 'Telefone',
  },
  sender: {
    info: 'Informação do remetente',
    name: 'Nome',
    address: 'Morada',
    email: 'Email',
    phone: 'Telefone',
  },
  destination: {
    info: 'Informação do destinatário',
    name: 'Nome',
    address: 'Morada',
    email: 'Email',
    phone: 'Telefone',
  },
  country: {
    PT:"Portugal",
    ES:"Espanha"
  },
};
