export default {
  listSuperAdmins: 'Super admins',
  createSuperAdmin: 'Adicionar super admin',
  representatives: 'Comerciais',
  createRepresentative: 'Adicionar comercial',
  listNetworks: 'Redes',
  createNetwork: 'Adicionar rede',
  createNetworkAdmin: 'Adicionar administrador de rede',
  listParcelShops: 'Lojas',
  createShop: 'Adicionar lojas',
  createShopUsers: 'Adicionar usuário de loja',
  zones: 'Zonas',
  zonesCreate: 'Adicionar zona',
  expeditions: 'Adicionar expedicões',
  settings: 'Configurações',
  reports: 'Relatórios',
  reportCommercial: 'Relatório comercial',
  reportShops: 'Relatório geral',
  reportSales: 'Relatório de vendas',
  reportQuality: 'relatório de qualidade',
  reportOperations: 'Relatório de Operações',
};
